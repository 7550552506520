:root {
  // PALETTE
  --cyprus: #00303c;
  --sherpaBlue: #014758;
  --halfBaked: #83c8bb;
  --jaggedIce: #bce7df;
  --boulder: #767676;
  --alto: #d8d8d8;
  --wildSand: #f6f6f6;
  --greenPea: #247534;
  --tulipTree: #f0a63c;
  --thunderBird: #d32113;
  --black: #000000;
  --white: #ffffff;
  --grey: #333333;
  --successDefault: #77bc1f;
  --primaryDefault: #0137ef;
  --primary10: #000f5c;
  --primary80: #bbc3ff;
  --primary85: rgba(1, 55, 239, 0.85);
  --neutral: #000f42;
  --neutral40: #666f8e;
  --neutral60: #9399ad;
  --background1: #fafafb;
  --neutralMain: #000f42;

  // ----------- THEME COLORS ----------------------------
  --primary-dark: var(--cyprus);
  --secondary-dark: var(--halfBaked);
  --tertiary-dark: var(--sherpaBlue);
  --quaternary-dark: var(--jaggedIce);

  --primary-light: var(--white);
  --secondary-light: var(--wildSand);
  --tertiary-light: var(--alto);
  --quaternary-light: var(--boulder);

  --success: var(--greenPea);
  --warning: var(--tulipTree);
  --error: var(--thunderBird);

  // ----------- FONTS -----------------------------------
  --font-family: var(--open-sans), sans-serif;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-bold: 600;

  // ----------- INTERMEDIATE LAYER COLORS -----------------------

  // backgrounds
  --background-primary-dark: var(--primary-dark);
  --background-secondary-dark: var(--secondary-dark);
  --background-tertiary-dark: var(--tertiary-dark);
  --background-quaternary-dark: var(--quaternary-dark);
  --background-primary-light: var(--primary-light);
  --background-secondary-light: var(--secondary-light);
  --background-tertiary-light: var(--tertiary-light);
  --background-quaternary-light: var(--quaternary-light);
  --background-primary: var(--primaryDefault);
  --background-primary-80: var(--primary80);
  --background-primary-85: var(--primary85);
  --background-success: var(--successDefault);
  --background-neutral-60: var(--neutral60);
  --background-1: var(--background1);

  // text
  --text-primary-dark: var(--primary-dark);
  --text-secondary-dark: var(--secondary-dark);
  --text-tertiary-dark: var(--tertiary-dark);
  --text-quaternary-dark: var(--quaternary-dark);
  --text-primary-light: var(--primary-light);
  --text-secondary-light: var(--quaternary-light);
  --text-tertiary-light: var(--tertiary-light);
  --text-quaternary-light: var(--secondary-light);
  --text-primary: var(--neutral);
  --text-primary-10: var(--primary10);
  --text-neutral-40: var(--neutral40);
  --text-neutral-main: var(--neutralMain);

  // border
  --border-primary-dark: var(--primary-dark);
  --border-secondary-dark: var(--secondary-dark);
  --border-tertiary-dark: var(--tertiary-dark);
  --border-quaternary-dark: var(--quaternary-dark);
  --border-primary-light: var(--primary-light);
  --border-secondary-light: var(--quaternary-light);
  --border-tertiary-light: var(--tertiary-light);
  --border-quaternary-light: var(--secondary-light);
  --border-neutral-60: var(--neutral60);

  // buttons
  --button-contained-primary-background: var(--primary-dark);
  --button-contained-primary-color: var(--secondary-dark);
  --button-contained-primary-border: var(--primary-dark);
  --button-contained-primary-background-hover: var(--tertiary-dark);
  --button-contained-primary-border-hover: var(--tertiary-dark);

  --button-contained-secondary-background: var(--secondary-dark);
  --button-contained-secondary-color: var(--primary-dark);
  --button-contained-secondary-border: var(--secondary-dark);
  --button-contained-secondary-background-hover: var(--quaternary-dark);
  --button-contained-secondary-border-hover: var(--quaternary-dark);

  --button-contained-tertiary-background: var(--secondary-light);
  --button-contained-tertiary-color: var(--primary-dark);
  --button-contained-tertiary-border: var(--tertiary-light);
  --button-contained-tertiary-background-hover: var(--secondary-light);
  --button-contained-tertiary-border-hover: var(--tertiary-light);

  --button-outlined-primary-color: var(--primary-dark);
  --button-outlined-primary-border: var(--primary-dark);
  --button-outlined-primary-border-hover: var(--secondary-dark);

  --button-outlined-secondary-color: var(--primary-light);
  --button-outlined-secondary-border: var(--primary-light);
  --button-outlined-secondary-border-hover: var(--secondary-dark);
}
