@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;

@use '@styles/normalize.scss';
@use '@styles/theme.scss';
@use '@styles/typography.scss';
@use '@components/UI/Loader/Loader.scss';
@use '@components/common/Media/MediaWrapper.module.scss';

@use '@styles/tealium';

@font-face {
  font-family: 'GothamNarrowBlackFallback';
  src: local('Arial Black');
  size-adjust: 81%;
  ascent-override: 80%;
  descent-override: 15%;
  line-gap-override: 71%;
}

@font-face {
  font-family: 'OpenSansFallback';
  src: local('Verdana');
  size-adjust: 96%;
  ascent-override: 92%;
  descent-override: normal;
  line-gap-override: 2%;
  line-gap-override: 13%;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  overscroll-behavior: none;
}

html:not(.cm-preview--studio) {
  scroll-behavior: smooth !important; // to override CMS CSS
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
strong,
b {
  font-weight: 700;
}
body {
  margin: 0;
  background-color: #fff;
  overscroll-behavior: none;
}
@media print {
  body {
    background-color: #fff;
  }
}
body::backdrop {
  background-color: #fff;
}

/**
* CSS Reset
*/

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

$images-dir: '^images/';

.accordion-title:focus {
  background-color: transparent;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

.lazy-load-wrapper {
  height: 100%;
  width: 100%;
  max-width: 100vw;

  img {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    object-fit: cover;
    vertical-align: bottom;
  }
}

span.lazy-load-image-background {
  font-size: 0;
}

.pac-container {
  z-index: 1201;
}

.pac-powered {
  text-align: right;
  background-color: var(--white);
  padding-right: 1rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

$primaryMain: #005abb;
$primaryDark: #013365;
$textLight: #ffffff;
$textDark: #2b2e31;
$secondaryMain: #ff9c65;
$secondaryDark: #ec6526;

.fade-enter {
  z-index: 1;
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

@keyframes ldio-iygribuesa {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ldio-iygribuesa div {
  left: 99px;
  top: 62px;
  position: absolute;
  animation: ldio-iygribuesa linear 1.5384615384615383s infinite;
  background: #82c6bd;
  width: 2px;
  height: 24px;
  border-radius: 0px / 0px;
  transform-origin: 1px 38px;
}
.ldio-iygribuesa div:nth-of-type(1) {
  transform: rotate(0deg);
  animation-delay: -1.4854111405835542s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(2) {
  transform: rotate(12.413793103448276deg);
  animation-delay: -1.4323607427055702s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(3) {
  transform: rotate(24.82758620689655deg);
  animation-delay: -1.379310344827586s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(4) {
  transform: rotate(37.241379310344826deg);
  animation-delay: -1.326259946949602s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(5) {
  transform: rotate(49.6551724137931deg);
  animation-delay: -1.273209549071618s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(6) {
  transform: rotate(62.06896551724138deg);
  animation-delay: -1.2201591511936338s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(7) {
  transform: rotate(74.48275862068965deg);
  animation-delay: -1.1671087533156497s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(8) {
  transform: rotate(86.89655172413794deg);
  animation-delay: -1.1140583554376657s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(9) {
  transform: rotate(99.3103448275862deg);
  animation-delay: -1.0610079575596816s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(10) {
  transform: rotate(111.72413793103448deg);
  animation-delay: -1.0079575596816976s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(11) {
  transform: rotate(124.13793103448276deg);
  animation-delay: -0.9549071618037135s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(12) {
  transform: rotate(136.55172413793105deg);
  animation-delay: -0.9018567639257293s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(13) {
  transform: rotate(148.9655172413793deg);
  animation-delay: -0.8488063660477453s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(14) {
  transform: rotate(161.3793103448276deg);
  animation-delay: -0.7957559681697612s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(15) {
  transform: rotate(173.79310344827587deg);
  animation-delay: -0.7427055702917771s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(16) {
  transform: rotate(186.20689655172413deg);
  animation-delay: -0.689655172413793s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(17) {
  transform: rotate(198.6206896551724deg);
  animation-delay: -0.636604774535809s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(18) {
  transform: rotate(211.0344827586207deg);
  animation-delay: -0.5835543766578248s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(19) {
  transform: rotate(223.44827586206895deg);
  animation-delay: -0.5305039787798408s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(20) {
  transform: rotate(235.86206896551724deg);
  animation-delay: -0.47745358090185674s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(21) {
  transform: rotate(248.27586206896552deg);
  animation-delay: -0.42440318302387264s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(22) {
  transform: rotate(260.6896551724138deg);
  animation-delay: -0.37135278514588854s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(23) {
  transform: rotate(273.1034482758621deg);
  animation-delay: -0.3183023872679045s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(24) {
  transform: rotate(285.51724137931035deg);
  animation-delay: -0.2652519893899204s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(25) {
  transform: rotate(297.9310344827586deg);
  animation-delay: -0.21220159151193632s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(26) {
  transform: rotate(310.3448275862069deg);
  animation-delay: -0.15915119363395225s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(27) {
  transform: rotate(322.7586206896552deg);
  animation-delay: -0.10610079575596816s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(28) {
  transform: rotate(335.17241379310343deg);
  animation-delay: -0.05305039787798408s;
  background: #82c6bd;
}
.ldio-iygribuesa div:nth-of-type(29) {
  transform: rotate(347.58620689655174deg);
  animation-delay: 0s;
  background: #82c6bd;
}
.loadingio-spinner-spinner-482gvyi63m {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  //background: rgba(NaN, NaN, NaN, 0);
}
.ldio-iygribuesa {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-iygribuesa div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

.swiper-pagination {
  margin: 20px auto 40px auto;
}

#store-locator-filter-modal {
  .sl-ui-filters-header {
    span {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
    }

    background: $primaryDark;
  }

  .sl-ui-filter-kinds-list {
    div {
      h3 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
      }

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
      }
      .sl-ui-filter-option-badge-selected {
        background: $primaryMain;
      }
    }
  }

  .sl-ui-filters-footer {
    .sl-ui-filter-clear-all {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      color: $textDark;
    }

    .sl-ui-filter-see-results {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      background: $secondaryMain;
      color: $textDark;
      border-radius: 100px;

      &:hover {
        background: $secondaryDark;
      }
    }
  }
}

.video-controls-icon-symbols {
  display: none;
}

.bg-dark-primary {
  background-color: var(--background-primary-dark);
}
.bg-dark-secondary {
  background-color: var(--background-secondary-dark); //TODO: check old color
}
.bg-dark-tertiary {
  background-color: var(--background-tertiary-dark); //TODO: check old color
}
.bg-dark-quaternary {
  background-color: var(--background-quaternary-dark);
}
.bg-light-primary {
  background-color: var(--background-primary-light);
}
.bg-light-secondary {
  background-color: var(--background-secondary-light);
}
.bg-light-tertiary {
  background-color: var(--background-tertiary-light);
}
.bg-light-quaternary {
  background-color: var(--background-quaternary-light);
}
.bg-black {
  background-color: var(--black);
}

.text-dark-primary {
  color: var(--text-primary-dark);
}
.text-dark-secondary {
  color: var(--text-secondary-dark);
}
.text-light-primary {
  color: var(--text-primary-light);
}
.text-light-secondary {
  color: var(--text-secondary-light);
}
.text-dark-primary-shadow {
  color: var(--text-primary-dark);
}
.text-dark-secondary-shadow {
  color: var(--text-secondary-dark);
}
.text-light-primary-shadow {
  color: var(--text-primary-light);
}
.text-light-secondary-shadow {
  color: var(--text-secondary-light);
}

.slider-container {
  width: 100%;
  min-height: inherit;
  & .slider-frame {
    height: 100% !important;
    min-height: inherit;
  }
}
.slider-list {
  min-height: inherit;
}
.slider-control-bottomcenter {
  width: 100%;
}
.hidden {
  display: none;
}
.content-visibility {
  content-visibility: auto;
}

#main_placement_1[data-lxcm-lv='anchor-buttons'] {
  position: sticky;
  top: 164px;
  z-index: 2;
  background-color: var(--white);
  transition: top 300ms ease-in-out;

  $c: &;

  @include breakpoints.media-breakpoint-up(md) {
    top: 156px;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    top: 172px;
  }

  @at-root {
    .is-scrolled ~ #main-viewport {
      #{$c} {
        top: 0;

        @include breakpoints.media-breakpoint-up(xxl) {
          top: 140px;
        }
      }
    }
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

#AmazonPayButton {
  width: 100% !important;
}
