@use '@styles/mixins/breakpoints' as breakpoints;

$shadow-dark-color: rgba(0, 0, 0, 0.5);
$shadow-light-color: rgba(255, 255, 255, 0.5);

@mixin add-shadow($after-class, $background-color) {
  @at-root {
    .block-left-top#{#{$after-class}},
    .block-left-middle#{#{$after-class}} {
      background: linear-gradient(90deg, $background-color 25%, transparent 50%);
    }

    .block-left-bottom#{#{$after-class}},
    .block-right-bottom#{#{$after-class}},
    .block-center-bottom#{#{$after-class}} {
      background: linear-gradient(0deg, $background-color 25%, transparent 50%);
    }

    .block-right-top#{#{$after-class}},
    .block-right-middle#{#{$after-class}} {
      background: linear-gradient(270deg, $background-color 25%, transparent 50%);
    }

    .block-center-middle#{#{$after-class}} {
      background: linear-gradient(0deg, $background-color 25%, transparent 50%);
    }
  }
}

.media-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  > div {
    height: inherit;
  }

  $mediaWrapper: &;

  &.is-video {
    padding-bottom: initial;
    height: auto;
  }

  picture,
  video,
  .video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loaded {
    + svg {
      display: none;
    }
  }

  .cms-section[class*='-shadow'] & {
    $shadow-section: &;

    &.has-overlay {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        bottom: 10px;
        background: transparent;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 25%, transparent 50%);

        $after-class: &;

        @include breakpoints.media-breakpoint-up(xl) {
          @include add-shadow($after-class, $shadow-light-color);
        }
      }

      @at-root {
        [class*='light']#{$shadow-section} {
          &::after {
            $after-class: &;

            @include breakpoints.media-breakpoint-up(xl) {
              @include add-shadow($after-class, $shadow-dark-color);
            }
          }
        }
      }
    }
  }
}
