@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;

/* Tealium Cookie Consent Banner */
#__tealiumGDPRecModal {
  .privacy_prompt1.explicit_consent {
    background-color: var(--background-primary-85);
    padding: 0;

    .privacy_prompt_container1 {
      padding: 24px 16px;

      @include breakpoints.media-breakpoint-up(md) {
        display: flex;
        gap: 64px;
        padding: 24px 64px;
      }

      .privacy_prompt_content1 {
        font-size: 12px;
        line-height: 150%;

        @include breakpoints.media-breakpoint-up(md) {
          font-size: 14px;
          padding-right: 30px;
          width: 70%;
        }
      }

      .privacy_prompt_content21 {
        display: flex;
        gap: 16px;
        margin-top: 24px;

        @include breakpoints.media-breakpoint-up(md) {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          margin-top: 0;
          width: 30%;
        }
      }

      #consent_prompt_submit,
      #consent_prompt_submitNo {
        background-color: var(--background-primary-80);
        border: 0;
        border-radius: 8px;
        color: var(--text-primary-10);
        height: auto;
        line-height: 40px;
        margin: 0;
        padding: 0;
        width: 100%;

        @include breakpoints.media-breakpoint-up(md) {
          max-width: 180px;
        }
      }

      #consent_prompt_submit {
        display: block;
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }

      #consent_prompt_pref {
        padding: 0 !important;
        text-decoration: none;
        width: 100%;

        @include breakpoints.media-breakpoint-up(md) {
          max-width: 180px;
        }

        .privacy_prompt_content2 {
          font-family: inherit;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
        }
      }

      .privacy_prompt_content2 {
        border: 1px solid var(--border-neutral-60);
        border-radius: 8px;
        display: block;
        line-height: 40px;
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        padding: 0;
        text-decoration: none;
        text-transform: initial;
        width: 100%;
      }
    }
  }
}

#consent_prompt_submitNo {
  position: relative;
  z-index: 1;
}

#__tealiumGDPRcpPrefs {
  .consent_preferences {
    .privacy_prompt {
      border: 0;
      border-radius: 8px;

      @include breakpoints.media-breakpoint-up(md) {
        max-width: 620px;
        left: 50%;
        margin-left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .sdgdpradv_headline {
        display: block;
        font-family: inherit;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: normal;
        margin-top: 40px;
        margin-bottom: 24px;
        text-align: center;
        text-transform: lowercase;
        width: 100%;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .sdgdpradv_td_status {
        display: block;
        height: 24px;
        padding-bottom: 0;
      }

      .close_btn_thick {
        background-color: var(--background-primary-light);
        border: 0;
        height: 24px;
        right: 16px;
        text-shadow: none;
        top: 16px;
        width: 24px;

        &::before,
        &::after {
          background-color: var(--background-primary);
          content: ' ';
          left: 12px;
          height: 20px;
          padding: 0;
          position: absolute;
          top: 2px;
          width: 1px;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }

      hr {
        display: none;
      }

      #__moreAge1 {
        color: var(--text-neutral-40);
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        text-align: center;

        @include breakpoints.media-breakpoint-up(md) {
          font-size: 16px;
        }
      }

      .privacy_prompt_footer {
        padding: 16px;

        @include breakpoints.media-breakpoint-up(md) {
          text-align: center;
        }

        #preferences_prompt_submit {
          background-color: var(--background-primary);
          border: 0;
          border-radius: 8px;
          color: var(--background-primary-light);
          font-family: inherit;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px;
          margin: auto;
          padding: 0;
          text-shadow: none;
          width: 100%;

          @include breakpoints.media-breakpoint-up(md) {
            display: inline-block;
            padding: 0 16px;
            width: auto;
          }
        }
      }
    }

    .privacy_prompt_content {
      padding: 16px;

      table tbody tr {
        display: revert;
      }

      input[type='checkbox'].toggle + label:before {
        border-radius: 50%;
        height: 20px;
        left: 1px;
        top: 1px;
        width: 20px;
      }

      input[type='checkbox'].toggle + label {
        background-color: var(--background-neutral-60);
        border: 1px solid var(--border-neutral-60);
        width: 44px;

        span,
        span.on {
          display: none;
        }
      }

      input[type='checkbox'].toggle:checked + label {
        background-color: var(--background-primary-light);
        box-shadow: none;
        border: 1px solid var(--border-neutral-60);
      }

      input[type='checkbox'].toggle {
        &:checked + label:before {
          background-color: var(--background-success);
          margin-left: -21px;
          top: 1px;
        }
      }

      .sdgdpradv_td_category {
        color: var(--text-primary);
        font-family: inherit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        @include breakpoints.media-breakpoint-up(md) {
          font-size: 16px;
        }
      }

      .sdgdpradv_td_desc {
        color: var(--text-primary);
        font-family: inherit;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;

        @include breakpoints.media-breakpoint-up(md) {
          font-size: 14px;
        }
      }
    }
  }
}
